<template>
  <div class="device-content">
    <div class="banner-box">
      <BannerSwipe/>
    </div>
    <!--    寺庙名称-->
    <div class="line-box">
      <CrossLine :title="getTempleName? getTempleName : ''"/>
    </div>
    <!--    设备列表-->
    <div class="device-box">
      <van-pull-refresh v-model="refreshing" @refresh="debouncedRefresh" loading-text="疯狂加载中..." success-text="刷新成功">
        <van-list
          v-model="isLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-for="(item,index) in deviceListData" >
            <DeviceItem :deviceitem="item" :key="index" @previewDevice="previewFn"></DeviceItem>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
    <div>
      <DeviceStatus :selectCommit="false" :show="show" :deviceStatusMap="deviceStatusMap"  @closeAction="closeAction"/>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import BannerSwipe from '@/components/Banner/BannerSwipe.vue'
import CrossLine from '@/components/common/CrossLine.vue'
import DeviceItem from '@/components/DeviceList/DeviceItem.vue'
import DeviceStatus from '@/components/DeviceList/DeviceStatus.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getDeviceListByTempleId } from '@/api/modules/device'
import { setShowToast } from '@/utils/request'
import _ from 'lodash';


export default {
  name: 'DeviceListIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {
    DeviceStatus,
    DeviceItem,
    CrossLine,
    BannerSwipe
  },
  props: {},
  // 这里用于存放数据
  data () {
    return {
      show:false,
      deviceImei: '',
      isLoading: false,
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      stopQuery: false,
      totalLength: 0
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('device',['deviceListData','finished']),
    ...mapGetters('temple', ['getTempleId', 'getTempleName']),
    //设备状态
    deviceStatusMap(){
      const device = this.deviceListData.find(obj=>obj.deviceImei===this.deviceImei)
      return device?device.deviceStatusMap : {}
    }
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations('device', ['setDeviceListData','removeDeviceListData','setFinished']),
    //获取设备列表
    async getDeviceListData () {
      this.$toast.loading({
        message: '加载中...',
        duration: 500
      })
      const { data: { data } } = await getDeviceListByTempleId({
        templeId: this.getTempleId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      })
      if(!data){
        return
      }
      if(data && data.length < 10){
        this.setFinished(true)
      }
      this.setDeviceListData(data)

    },
    onLoad () {
        if(this.refreshing){
          this.removeDeviceListData()
          this.refreshing = false
          this.pageIndex = 1
          console.log(this.pageIndex)
        }
        if(this.finished){
          return
        }
        //查询数据
        this.getDeviceListData().then(() => {
          this.refreshing = false
          this.isLoading = false
        })
        // this.isLoading = false
        this.pageIndex++
    },
    onRefresh () {
      // 清空列表数据
      // this.removeDeviceListData()
      this.setFinished(false)
      // this.pageIndex = 1;
       // 将 loading 设置为 true，表示处于加载状态
       this.isLoading = true
      // 重新加载数据
       this.onLoad()
    },
    previewFn(deviceImei){
      this.deviceImei = deviceImei
      this.show = true
    },
    closeAction(){
      this.show = false
    },
    debouncedRefresh: _.debounce(function() {
      this.onRefresh();
    }, 1500), // 1000毫秒延迟，根据需要调整
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    setShowToast(false)
    //获取设备列表
    //this.removeDeviceListData()
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {

  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
    this.removeDeviceListData()
    this.setFinished(false)
    setShowToast(true)
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.device-content {
  .banner-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 174px;
    background: #ffffff;
  }

  .line-box {
    /* 根据实际样式设置 */
    height: 84px; /* CrossLine的高度 */
    position: fixed;
    top: 174px;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
  }

  .device-box {
    margin-top: 258px;

  }

}


</style>
